import { Box, BoxProps, alpha, useTheme } from '@mui/material';
import { Link } from '@remix-run/react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import BookTile from '~/components/molecules/BookTile';

import BookTypes from '~/types/app/book';
import { mergeSx } from '~/utils/mui';
import { useCurrentUser } from '~/utils/user';

interface BookCarouselProps extends BoxProps {
    id: string;
    darken?: boolean;
    books: Array<BookTypes>;
    maxWidth?: 'xl' | 'xxl';
    showPricingInformation?: boolean;
}

const BookCarousel = ({ id, books, darken, maxWidth, showPricingInformation, sx, ...props }: BookCarouselProps) => {
    const theme = useTheme();
    const { user } = useCurrentUser();

    if (!books || books?.length < 1) {
        return null;
    }

    const perPageXl = maxWidth && maxWidth === 'xxl' ? [6, 5, 4, 3, 2] : [5, 4, 4, 3, 2];

    let showPrice: boolean;
    if (user.organisation.isOrganisationUser) {
        showPrice = false;
    } else {
        showPrice = !!showPricingInformation;
    }

    return (
        <>
            <Box
                sx={mergeSx(
                    {
                        width: 1,
                        '.splide__track': { py: '3px' },
                        '.splide__arrow': {
                            opacity: '1',
                            borderRadius: theme.radius.all,
                            backgroundColor: alpha(theme.palette.cta.main, 0.07),
                            svg: { fill: theme.palette.cta.main, opacity: 1 },
                            width: 40,
                            height: 40,
                            ':disabled': {
                                opacity: 0,
                            },
                            '&:focus-visible': {
                                boxShadow: theme.shadows[24],
                                outline: 'none !important',
                            },
                        },
                        '.splide__arrow--prev': {
                            transform: 'translateX(-100%)',
                            [theme.breakpoints.up('md')]: {
                                transform: 'translateX(-150%)',
                            },
                        },
                        '.splide__arrow--prev:hover,.splide__arrow--next:hover': {
                            backgroundColor: alpha(theme.palette.cta.main, 0.15),
                        },
                        '.splide__arrow--next': {
                            transform: 'translateX(100%)',
                            [theme.breakpoints.up('md')]: {
                                transform: 'translateX(150%)',
                            },
                        },
                    },
                    sx,
                )}
                {...props}
            >
                <Splide
                    id={`splide-list-${id}`}
                    options={{
                        type: 'rewind',
                        gap: theme.spacing(6),
                        pagination: false,
                        lazyLoad: true,
                        mediaQuery: 'min',
                        padding: { left: 0, right: 0 },
                        perPage: perPageXl[0],
                        breakpoints: {
                            [theme.breakpoints.values.xs]: {
                                perPage: perPageXl[4],
                            },
                            [theme.breakpoints.values.sm]: {
                                perPage: perPageXl[3],
                            },
                            [theme.breakpoints.values.md]: {
                                perPage: perPageXl[2],
                            },
                            [theme.breakpoints.values.lg]: {
                                perPage: perPageXl[1],
                            },
                            [theme.breakpoints.values.xl]: {
                                perPage: perPageXl[0],
                            },
                        },
                    }}
                >
                    {books.map((book) => {
                        if (!book) return null;
                        return (
                            // transform on SplideSlide is a hack to make the rotate animation of the book tile image work on safari
                            <SplideSlide key={`splide-item-${book.id}`} style={{ transform: `translate(-1px)` }}>
                                <BookTile
                                    hideProgress
                                    darken={darken}
                                    hideHighlights
                                    book={book}
                                    component={Link}
                                    to={book.meta.url}
                                    showPricingInformation={showPrice}
                                />
                            </SplideSlide>
                        );
                    })}
                </Splide>
            </Box>
        </>
    );
};

export default BookCarousel;
